﻿import FinesAndPenaltiesModule from "../Utilities/FinesAndPenaltiesModule";

export default class NewFine extends FinesAndPenaltiesModule {
	constructor() {
		super("/fine/new");

		this.$this = this;

		this.load = this.load.bind(this);
		this.unload = this.unload.bind(this);
		this.reset = this.reset.bind(this);
	}

	load() {
		super.load();

		// Forms and buttons
		this.initialPCNForm = document.getElementById("VerifyPCN");
		this.existingPCNForm = document.getElementById("ExistingPCN");
		this.newPCNForm = document.getElementById("NewPCN");
		
		this.submitButton = document.getElementById("SubmitButton");
		this.submitButton.addEventListener("click", this.e_HandleSubmit.bind(this));
		this.findDetailsButton = document.getElementById("FindDetailsButton");
		this.findDetailsButton.addEventListener("click", this.e_HandleFindDetailsClick.bind(this));
		
		// Date Pickers
		this.receivedDate = new window.Pikaday({ field: document.getElementById("ReceivedDate"), format: "DD/MM/YYYY" });
		this.noticeDate = new window.Pikaday({ field: document.getElementById("NoticeDate"), format: "DD/MM/YYYY" });
		this.incidentDate = new window.Pikaday({ field: document.getElementById("Fine_IncidentDate"), format: "DD/MM/YYYY" });

		// State
		this.actionIsOngoing = false;
		this.fineIsVerified = false;
		this.fineIsNew = false;
		this.loadedDropdowns = [];
		
		document.querySelector("#PCNNumbers").addEventListener("keydown", this.e_PCNNumbers_OnChange.bind(this));
		
		Promise.all([
			this.m_LoadClients(),
			this.m_LoadFineClassifications(),
			this.m_LoadFineStatuses(),
			this.m_LoadLeasingTypes(),
			this.m_LoadLocalAuthorities()
		]);
	}

	unload() {
		super.unload();

		document.querySelector("#PCNNumbers").removeEventListener("keydown", this.e_PCNNumbers_OnChange.bind(this));

		this.actionIsOngoing = false;
		this.fineIsVerified = false;
		this.fineIsNew = false;

		this.receivedDate.destroy();
		this.noticeDate.destroy();
		this.incidentDate.destroy();
		
		this.newPCNForm.classList.add("hidden");
		this.existingPCNForm.classList.add("hidden");
		this.initialPCNForm.classList.remove("hidden");
		this.submitButton.removeEventListener("click", this.e_HandleSubmit.bind(this));
		this.initialPCNForm.reset();
		this.initialPCNForm = null;
		this.existingPCNForm.reset();
		this.existingPCNForm = null;
		this.newPCNForm.reset();
		this.newPCNForm = null;
		this.submitButton = null;
	}

	reset() {
		super.reset();

		console.debug("[NewFine] reset called");
		this.initialPCNForm.reset();
		this.existingPCNForm.reset();
		this.existingPCNForm.style.display = "none";
		this.newPCNForm.reset();
		this.newPCNForm.style.display = "none";
		document.querySelector("#ExistingFine_CurrentlyMissing").classList.add("hidden");

		this.actionIsOngoing = false;
		this.fineIsVerified = false;
		this.fineIsNew = false;
	}

	async m_LoadClients() {
		if (this.loadedDropdowns["Client"] === undefined)
			this.loadedDropdowns["Client"] = new window.Choices(document.querySelector("#Fine_ClientId"));

		let clientList = {};
		try {
			let data = await m_FetchGet("/api/v2/Clients/All", {
				"fields": "id,name,parentClientId",
				"order[name]": "asc"
			});
			data.forEach(client => clientList[client.id] = { name: client.name, parentClientId: client.parentClientId});

			let choicesClientList = [{
				label: "Select...",
				value: "-1",
				selected: true,
				disabled: true
			}];
			let counter = 1;

			Object.keys(clientList).filter(clientKey => clientList[clientKey].parentClientId === null).forEach(clientId => {
				let client = clientList[clientId];
				let groupObject = {
					label: client.name,
					id: counter,
					disabled: false,
					choices: [
						{ label: client.name, value: clientId }
					]
				};

				Object.keys(clientList).filter(clientKey => clientList[clientKey].parentClientId === clientId).forEach(childClientId => {
					let childClient = clientList[childClientId];
					groupObject.choices.push({ label: childClient.name, value: childClientId });
				});

				choicesClientList.push(groupObject);
				counter++;
			});

			this.loadedDropdowns["Client"].setChoices(
				choicesClientList,
				"value",
				"label",
				true
			);
		} catch (x) {
			console.warn("Failed to load Clients");
		}
	}

	async m_LoadFineClassifications() {
		if (this.loadedDropdowns["FineClassification_New"] === undefined)
			this.loadedDropdowns["FineClassification_New"] = new window.Choices(document.querySelector("#NewFine_FineClassificationId"));
		if (this.loadedDropdowns["FineClassification_Existing"] === undefined)
			this.loadedDropdowns["FineClassification_Existing"] = new window.Choices(document.querySelector("#ExistingFine_FineClassificationId"));

		let fineClassificationsChoicesObjects = [{
			label: "Select...",
			value: "-1",
			selected: true,
			disabled: true
		}];
		try {
			let data = await m_FetchGet("/api/v2/FineClassifications", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(fineClassification => fineClassificationsChoicesObjects.push({ value: fineClassification.id, label: fineClassification.name }));

			this.loadedDropdowns["FineClassification_New"].setChoices(fineClassificationsChoicesObjects);
			this.loadedDropdowns["FineClassification_Existing"].setChoices(fineClassificationsChoicesObjects);
		} catch(x) {
			console.warn("Failed to load Fine Classifications");
		}
	}

	async m_LoadFineStatuses() {
		if (this.loadedDropdowns["FineStatus_New"] === undefined)
			this.loadedDropdowns["FineStatus_New"] = new window.Choices(document.querySelector("#NewFine_FineStatusId"));
		if (this.loadedDropdowns["FineStatus_Existing"] === undefined)
			this.loadedDropdowns["FineStatus_Existing"] = new window.Choices(document.querySelector("#ExistingFine_FineStatusId"));

		let fineStatusesChoicesObjects = [{
			label: "Select...",
			value: "-1",
			selected: true,
			disabled: true
		}];
		try {
			let data = await m_FetchGet("/api/v2/FineStatuses", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(fineStatus => fineStatusesChoicesObjects.push({ value: fineStatus.id, label: fineStatus.name }));

			this.loadedDropdowns["FineStatus_New"].setChoices(fineStatusesChoicesObjects);
			this.loadedDropdowns["FineStatus_Existing"].setChoices(fineStatusesChoicesObjects);
		} catch(x) {
			console.warn("Failed to load Fine Statuses");
		}
	}

	async m_LoadLeasingTypes() {
		if (this.loadedDropdowns["LeasingType"] === undefined)
			this.loadedDropdowns["LeasingType"] = new window.Choices(document.querySelector("#Fine_LeasingTypeId"));

		let leasingTypesChoicesObjects = [{
			label: "Select...",
			value: "-1",
			selected: true,
			disabled: true
		}];
		try {
			let data = await m_FetchGet("/api/v2/LeasingTypes", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(leasingType => leasingTypesChoicesObjects.push({ value: leasingType.id, label: leasingType.name }));

			this.loadedDropdowns["LeasingType"].setChoices(leasingTypesChoicesObjects);
		} catch(x) {
			console.warn("Failed to load Leasing Types");
		}
	}

	async m_LoadLocalAuthorities() {
		if (this.loadedDropdowns["LocalAuthority"] === undefined)
			this.loadedDropdowns["LocalAuthority"] = new window.Choices(document.querySelector("#Fine_LocalAuthorityId"));

		let localAuthoritiesChoicesObject = [{
			label: "Select...",
			value: "-1",
			selected: true,
			disabled: true
		}];
		try {
			let data = await m_FetchGet("/api/v2/LocalAuthorities", {
				"fields": "id,name",
				"order[name]": "asc"
			});
			data.forEach(localAuthority => localAuthoritiesChoicesObject.push({ value: localAuthority.id, label: localAuthority.name }));

			this.loadedDropdowns["LocalAuthority"].setChoices(localAuthoritiesChoicesObject);
		} catch(x) {
			console.warn("Failed to load Local Authorities");
		}
	}
	
	e_PCNNumbers_OnChange() {
		if (this.fineIsVerified)
		{
			this.fineIsVerified = false;
			this.existingPCNForm.style.display = "none";
			this.newPCNForm.style.display = "none";
			this.submitButton.innerHTML = "Next";
		}
	}
	
	async e_HandleFindDetailsClick(event) {
		event.preventDefault();
		
		if (this.actionIsOngoing) return;
		this.actionIsOngoing = true;
		
		document.getElementById("FindDetailsButton").innerHTML = this.spinnerIcon;
				
		try {
			let data = await m_FetchGet("/fine/new/details", {
				"PCNNumber": document.getElementById("PCNNumbers").value,
				"Registration": document.getElementById("Fine_Registration").value,
				"IncidentDate": document.getElementById("Fine_IncidentDate").value
			});
			
			if (data.message !== "") {
				document.getElementById("FindDetailsAlert").querySelector("p").innerText = data.message;
				document.getElementById("FindDetailsAlert").classList.remove("hidden");
			} else document.getElementById("FindDetailsAlert").classList.add("hidden");

			if (data.localAuthority !== "") this.loadedDropdowns["LocalAuthority"].setChoiceByValue(data.localAuthority);
			if (data.rentalAgreement !== "") document.getElementById("Fine_RentalAgreement").value = data.rentalAgreement;
			if (data.client !== "") {
				await this.m_LoadClients();
				this.loadedDropdowns["Client"].setChoiceByValue(data.client);
			}
			if (data.leasingType !== "") this.loadedDropdowns["LeasingType"].setChoiceByValue(data.leasingType);
			this.actionIsOngoing = false;
			document.getElementById("FindDetailsButton").innerText = "Find Details";
		} catch(x) {
			m_ShowWarning("Find Details Failed", "Unable to find any details with the current information.");
			this.actionIsOngoing = false;
			document.getElementById("FindDetailsButton").innerText = "Find Details";
		}
	}

	async e_HandleSubmit() {
		if (this.actionIsOngoing) return;
		this.actionIsOngoing = true;

		this.submitButton.insertAdjacentHTML("beforeend", "<i class=\"ml-2 fas fa-circle-notch fa-spin\"></i>");

		if (!this.fineIsVerified) {
			try {
				let data = await m_FetchGet("/fine/new/verify", new FormData(this.initialPCNForm));

				this.fineIsVerified = true;
				if (data.newFine) {
					console.debug("[NewFine] Got form response. System says this fine is new. Showing newPCNForm.");
					this.fineIsNew = true;
					this.newPCNForm.querySelector("[name=NoticeDate]").value = data.noticeDate;
					this.newPCNForm.querySelector("[name=ReceivedDate]").value = data.receivedDate;
					this.newPCNForm.querySelector("#Fine_PCNNumber").value = document.getElementById("PCNNumbers").value;
					this.newPCNForm.style.display = "block";
					this.submitButton.innerHTML = "Save";
				} else {
					console.debug("[NewFine] Got form response. System says this fine is not new. Showing existingPCNForm.");
					this.fineIsNew = false;
					data.missing.forEach(pcn => {
						document.querySelector("#ExistingFine_CurrentlyMissing").classList.remove("hidden");
						document.querySelector("#ExistingFine_CurrentlyMissing ul").insertAdjacentHTML("beforeend", "<li class='text-sm leading-5 text-gray-500'>- " + pcn + "</li>");
					});
					this.existingPCNForm.querySelector("[name=FineNumbers]").value = data.fineIds;
					this.existingPCNForm.querySelector("[name=NoticeDate]").value = data.noticeDate;
					this.existingPCNForm.querySelector("[name=ReceivedDate]").value = data.receivedDate;
					this.existingPCNForm.style.display = "block";
					this.submitButton.innerHTML = "Save";
				}

				this.actionIsOngoing = false;
			} catch(x) {
				if (this.fineIsVerified) this.submitButton.innerHTML = "Save";
				else this.submitButton.innerHTML = "Next";
				this.actionIsOngoing = false;
			}
		}
		else
		{
			let theFormToSend;
			if (!this.fineIsNew) theFormToSend = this.existingPCNForm;
			else if (this.fineIsNew) theFormToSend = this.newPCNForm;

			console.debug("[NewFine] Submitting form. fineIsVerified = " + this.fineIsVerified + ", fineIsNew = " + this.fineIsNew);
			
			try {
				let response = await window.m_FetchPost("/fine/new", new FormData(theFormToSend))
				
				m_ShowSuccess("Success", "The fine was added to the system.");
				this.fineIsNew = false;
				this.initialPCNForm.reset();
				this.existingPCNForm.reset();
				this.newPCNForm.reset();
				window.turbolinks.visit("/fine/" + response.fineId);
				document.querySelector("#ExistingFine_CurrentlyMissing").classList.add("hidden");
				this.actionIsOngoing = false;
			} catch(x) {
				if (this.fineIsVerified) this.submitButton.innerHTML = "Save";
				else this.submitButton.innerHTML = "Next";
				this.actionIsOngoing = false;
			}
		}
	}
}